export default class Feedback {
  constructor(rate, detail) {
    this._rate = rate;
    this._detail = detail;
  }

  detail() {
    return this._detail;
  }

  rate() {
    return this._rate;
  }
}
