import MuiMarkdown, { getOverrides } from "mui-markdown";

function Markdown({ content }) {
  return (
    <MuiMarkdown
      overrides={{
        ...getOverrides(),
        h1: {
          props: {
            style: { fontSize: "2rem" },
          },
        },
        h2: {
          props: {
            style: { fontSize: "1.9rem" },
          },
        },
        h3: {
          props: {
            style: { fontSize: "1.7rem" },
          },
        },
        h4: {
          props: {
            style: { fontSize: "1.5rem" },
          },
        },
      }}
    >
      {content}
    </MuiMarkdown>
  );
}

export default Markdown;
