import { AuthStorage } from "../storage/authStorage";

class ApiRequester {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.authStorage = new AuthStorage();
  }

  post(path, body, withAuthorization, contentType = "application/json") {
    return fetch(this.apiUrl + path, {
      method: "POST",
      headers: this._headers(withAuthorization, contentType),
      body: contentType === "multipart/form-data" ? body : JSON.stringify(body)
    }).then(this._reject_response_if_unauthorized_or_passthrough);
  }

  get(path, withAuthorization) {
    return fetch(this.apiUrl + path, {
      method: "GET",
      headers: this._headers(withAuthorization)
    }).then(this._reject_response_if_unauthorized_or_passthrough);
  }

  _reject_response_if_unauthorized_or_passthrough(response) {
    if (response.status === 403) {
      // TODO analyze if we move the logic to redirect to Login page here
      return Promise.reject({ status: 403 });
    } else {
      return response.json();
    }
  }

  _headers(withAuthorization, contentType) {
    let headers = {};
    if (contentType !== "multipart/form-data") {
      headers = { "Content-Type": contentType };
    }
    if (withAuthorization) {
      headers["Authorization-id"] = this.authStorage.getUserId();
      headers["Authorization"] = `Bearer ${this.authStorage.getUserToken()}`;
    }
    return new Headers(headers);
  }
}

export default ApiRequester;
