import { Box } from "@mui/material";
import FramedContainer from "./framed-container/FramedContainer";
import FilledButton from "./buttons/FilledButton";
import { useState } from "react";
import ApiClient from "../services/ApiClient";
import { useNavigate } from "react-router-dom";
import Markdown from "./Markdown";
import { LOGIN_PATH } from "../routes";

function GeneratedContentPlanning({
  contentPlanning,
  contentPlanningId,
  readOnly,
}) {
  const apiClient = new ApiClient();
  const navigate = useNavigate();

  const [isExpandingActivities, setExpandingActivities] = useState(false);
  const [isExpandingEvaluation, setExpandingEvaluation] = useState(false);
  const [activities, setActivities] = useState();
  const [evaluations, setEvaluations] = useState();

  const hasContentPlanning = () => contentPlanning !== "";

  const expandActivities = () => {
    setExpandingActivities(true);
    apiClient
      .expandActivities(contentPlanning, contentPlanningId)
      .then((response) => {
        setExpandingActivities(false);
        setActivities(response.content);
      })
      .catch((error) => {
        if (error.status === 403) {
          navigate(LOGIN_PATH);
        } else {
          setExpandingActivities(false);
          setActivities(undefined);
        }
      });
  };
  const expandEvaluation = () => {
    setExpandingEvaluation(true);
    apiClient
      .expandEvaluation(contentPlanning, contentPlanningId)
      .then((response) => {
        setExpandingEvaluation(false);
        setEvaluations(response.content);
      })
      .catch((error) => {
        if (error.status === 403) {
          navigate(LOGIN_PATH);
        } else {
          setExpandingEvaluation(false);
          setEvaluations(undefined);
        }
      });
  };

  const renderActivities = () => {
    if (activities) {
      return (
        <FramedContainer>
          <Box sx={{ width: "100%" }}>
            <Markdown content={activities}></Markdown>
          </Box>
        </FramedContainer>
      );
    }
  };

  const renderEvaluations = () => {
    if (evaluations) {
      return (
        <FramedContainer>
          <Box sx={{ width: "100%" }}>
            <Markdown content={evaluations}></Markdown>
          </Box>
        </FramedContainer>
      );
    }
  };

  const renderExpansionOptions = () => {
    if (!readOnly) {
      return (
        <Box
          hidden={readOnly}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            marginTop: "30px",
          }}
        >
          <FilledButton
            onClick={expandActivities}
            disabled={isExpandingActivities}
          >
            Expandir actividades
          </FilledButton>

          <FilledButton
            onClick={expandEvaluation}
            disabled={isExpandingEvaluation}
          >
            Expandir evaluaciones
          </FilledButton>
        </Box>
      );
    }
  };

  const renderContentPlanning = () => (
    <FramedContainer>
      <Box sx={{ width: "100%" }}>
        <Markdown content={contentPlanning}></Markdown>
      </Box>
      {renderExpansionOptions()}
      {renderActivities()}
      {renderEvaluations()}
    </FramedContainer>
  );

  return hasContentPlanning() ? renderContentPlanning() : undefined;
}

export default GeneratedContentPlanning;
