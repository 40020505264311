import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ApiClient from "../services/ApiClient";
import { LOGIN_PATH } from "../routes";
import { useNavigate } from "react-router-dom";
import GeneratedContentPlanning from "../components/GeneratedContentPlanning";

function ContentPlanningHistory() {
  const [selectedContentPlanning, setSelectedContentPlanning] =
    useState(undefined);
  const [isFetchingHistory, setFetchingHistory] = useState(true);
  const [contentPlanningHistory, setContentPlanningHistory] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const apiClient = new ApiClient();
    const getContentPlanningHistory = () => {
      setFetchingHistory(true);
      apiClient
        .contentPlanningHistory()
        .then((history) => {
          setFetchingHistory(false);
          setContentPlanningHistory(history);
        })
        .catch((error) => {
          if (error.status === 403) {
            navigate(LOGIN_PATH);
          } else {
            setFetchingHistory(false);
            setContentPlanningHistory([]);
          }
        });
    };

    getContentPlanningHistory();
  }, [navigate]);

  const hasSelectedContentPlanning = () => {
    return selectedContentPlanning !== undefined;
  };

  const selectedContentPlanningId = () => {
    return hasSelectedContentPlanning()
      ? selectedContentPlanning.id
      : undefined;
  };

  const renderContentPlanningList = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <List
          sx={{
            width: "300px",
            flexShrink: 0,
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          {contentPlanningHistory.map((contentPlanning) => {
            const id = contentPlanning.id();
            const title = contentPlanning.subject();
            const subTitle = contentPlanning.creationDate();
            return (
              <ListItem key={id} disablePadding>
                <ListItemButton
                  selected={selectedContentPlanningId() === id}
                  onClick={() => setSelectedContentPlanning(contentPlanning)}
                >
                  <ListItemText primary={title} secondary={subTitle} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  const renderSelectedContentPlanning = () => {
    if (hasSelectedContentPlanning()) {
      return (
        <Box sx={{ flexGrow: 1, p: 3, overflow: "auto", height: "100vh" }}>
          <Box sx={{ flexGrow: 1, pl: 3 }}>
            <Typography variant="h4" gutterBottom>
              {selectedContentPlanning.subject()}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              Generada el: {selectedContentPlanning.creationDate()}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Parámetros:
            </Typography>
            <Typography variant="body1" component="p">
              <strong>Región:</strong> {selectedContentPlanning.region()}
            </Typography>
            <Typography variant="body1" component="p">
              <strong>Grado:</strong> {selectedContentPlanning.grade()}
            </Typography>
            <Typography variant="body1" component="p">
              <strong>Tema:</strong> {selectedContentPlanning.topic()}
            </Typography>
            <Typography variant="body1" component="p">
              <strong>Unidad de tiempo:</strong> {selectedContentPlanning.timeUnit()}
            </Typography>
            <Typography variant="body1" component="p">
              <strong>Instrucción:</strong>{" "}
              {selectedContentPlanning.instruction()}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Contenido generado:
            </Typography>
            <GeneratedContentPlanning
              contentPlanning={selectedContentPlanning.content()}
              contentPlanningId="id"
              readOnly
            />
          </Box>
        </Box>
      );
    }
  };

  const renderHistory = () => {
    if (isFetchingHistory) return <CircularProgress />;
    return (
      <>
        {renderContentPlanningList()}
        {renderSelectedContentPlanning()}
      </>
    );
  };

  return <Box sx={{ display: "flex", height: "100vh" }}>{renderHistory()}</Box>;
}

export default ContentPlanningHistory;
