import "./App.css";
import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import EventNoteIcon from "@mui/icons-material/EventNote";
import HistoryIcon from "@mui/icons-material/History";
import ContentPlanning from "./screens/ContentPlanning";
import ContentArea from "./components/ContentArea";
import ContextDetail from "./screens/ContextDetail";
import ContentPlanningHistory from "./screens/ContentPlanningHistory";

function App() {
  const DRAWER_ITEMS = {
    CONTENT_PLANNING: "Planificación",
    CONTENT_PLANNING_HISTORY: "Historial de planificaciones",
    CONTEXT: "Contexto",
  };

  const [selectedItem, setSelectedItem] = useState(
    DRAWER_ITEMS.CONTENT_PLANNING,
  );

  const renderDrawerItem = (text, icon) => {
    const isSelected = selectedItem === text;
    return (
      <ListItem
        key={text}
        disablePadding
        selected={isSelected}
        onClick={() => setSelectedItem(text)}
      >
        <ListItemButton selected={isSelected}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
      </ListItem>
    );
  };

  const renderSelectedContent = () => {
    switch (selectedItem) {
      case DRAWER_ITEMS.CONTEXT:
        return <ContextDetail />;
      case DRAWER_ITEMS.CONTENT_PLANNING_HISTORY:
        return <ContentPlanningHistory />;
      case DRAWER_ITEMS.CONTENT_PLANNING:
      default:
        return <ContentPlanning />;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <List>
          {renderDrawerItem(DRAWER_ITEMS.CONTEXT, <ArticleIcon />)}
          {renderDrawerItem(DRAWER_ITEMS.CONTENT_PLANNING, <EventNoteIcon />)}
          {renderDrawerItem(
            DRAWER_ITEMS.CONTENT_PLANNING_HISTORY,
            <HistoryIcon />,
          )}
        </List>
      </Drawer>
      <ContentArea>{renderSelectedContent()}</ContentArea>
    </Box>
  );
}

export default App;
