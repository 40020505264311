import ApiRequester from "./ApiRequester";
import { AuthStorage } from "../storage/authStorage";
import GeneratedContentPlanning from "../app/GeneratedContentPlanning";

class ApiClient {
  constructor() {
    this.apiRequester = new ApiRequester();
    this.authStorage = new AuthStorage();
  }

  generateContentPlanning(
    subject,
    grade,
    region,
    instruction,
    topic,
    timeUnit,
  ) {
    const body = {
      subject,
      grade,
      region,
      instruction,
      topic,
      time_unit: timeUnit,
      user_id: this.authStorage.getUserId(),
    };
    return this.apiRequester.post("/content-planning", body, true);
  }

  expandEvaluation(contentPlanning, contentPlanningId) {
    const body = { data: contentPlanning, id: contentPlanningId };
    return this.apiRequester.post(
      "/content-planning/expand/evaluations",
      body,
      true,
    );
  }

  saveContentPlanningFeedback(feedback, contentPlanningId) {
    const body = {
      rate: feedback.rate(),
      detail: feedback.detail(),
      content_planning_id: contentPlanningId,
    };
    return this.apiRequester.post("/feedback", body, true);
  }

  saveUser(user) {
    const body = {
      id: user.id(),
      token: user.token(),
      full_name: user.fullName(),
      email: user.email(),
    };
    return this.apiRequester.post("/user", body, true);
  }

  listContextFiles() {
    return this.apiRequester.get("/context-files", true);
  }

  addFilesToContext(formData) {
    console.log([...formData]);
    const headers = "multipart/form-data";
    return this.apiRequester.post("/upload-file", formData, true, headers);
  }

  expandActivities(contentPlanning, contentPlanningId) {
    const body = { data: contentPlanning, id: contentPlanningId };
    return this.apiRequester.post(
      "/content-planning/expand/activities",
      body,
      true,
    );
  }

  contentPlanningHistory() {
    return this.apiRequester
      .get("/content-planning/history", true)
      .then((response) => {
        const contentPlanningHistory = response.content;
        return contentPlanningHistory.map(
          ({
            id,
            grade,
            instruction,
            region,
            subject,
            response,
            created_at,
            topic,
            time_unit,
          }) =>
            new GeneratedContentPlanning(
              id,
              grade,
              instruction,
              region,
              subject,
              response,
              created_at,
              topic,
              time_unit,
            ),
        );
      });
  }
}

export default ApiClient;
