import { Box, FormLabel, TextField } from "@mui/material";

function Text({
  id,
  label,
  value,
  onChange,
  type = "string",
  placeholder = "",
  disabled = false,
  minimumValue,
}) {
  const inputProps = minimumValue !== undefined ? { min: minimumValue } : {};
  return (
    <Box width="100%">
      <FormLabel id={id}>{label}</FormLabel>
      <TextField
        aria-labelledby={id}
        variant="outlined"
        sx={{ width: "100%" }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        InputProps={{
            inputProps: inputProps
        }}
      />
    </Box>
  );
}

export default Text;
