import { Alert, Box } from "@mui/material";
import { useState } from "react";
import FilledButton from "../buttons/FilledButton";
import Select from "../fields/Select";
import Text from "../fields/Text";
import FramedContainer from "../framed-container/FramedContainer";
import { LOGIN_PATH } from "../../routes";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../services/ApiClient";

function ContentPlanningForm({ onContentPlanningChange }) {
  const [isGeneratingContentPlanning, setGeneratingContentPlanning] =
    useState(false);
  const [subject, setSubject] = useState("");
  const [grade, setGrade] = useState("");
  const [region, setRegion] = useState("");
  const [instruction, setInstruction] = useState();
  const [topic, setTopic] = useState("");
  const [timeUnit, setTimeUnit] = useState();
  const [formError, setFormError] = useState(false);

  const apiClient = new ApiClient();
  const navigate = useNavigate();

  const labelFrom = (value, options) => {
    const selectedOption = options.find((option) => option.value === value);
    return selectedOption.label;
  };

  const onClick = () => {
    setGeneratingContentPlanning(true);
    apiClient
      .generateContentPlanning(
        labelFrom(subject, subjectOptions),
        labelFrom(grade, gradeOptions),
        labelFrom(region, regionOptions),
        instruction,
        topic,
        timeUnit,
      )
      .then((data) => {
        setFormError(false);
        onContentPlanningChange(data.content_planning);
        setGeneratingContentPlanning(false);
      })
      .catch((error) => {
        if (error.status === 403) {
          navigate(LOGIN_PATH);
        } else {
          setFormError(true);
          onContentPlanningChange("");
          setGeneratingContentPlanning(false);
        }
      });
  };

  const updateField = (setField) => (event) => {
    setField(event.target.value);
  };

  const renderError = () => {
    return formError ? (
      <Alert severity="error" sx={{ marginTop: "15px" }}>
        Ocurrió un error al generar la respuesta.
      </Alert>
    ) : undefined;
  };

  const isSubmitButtonEnabled =
    subject &&
    grade &&
    region &&
    topic &&
    timeUnit &&
    !isGeneratingContentPlanning;

  const gradeOptions = [
    { value: "firstGrade", label: "Primer grado" },
    { value: "secondGrade", label: "Segundo grado" },
    { value: "thirdGrade", label: "Tercer grado" },
    { value: "fourthGrade", label: "Cuarto grado" },
    { value: "fifthGrade", label: "Quinto grado" },
    { value: "sixthGrade", label: "Sexto grado" },
    { value: "seventhGrade", label: "Séptimo grado" },
  ];

  const subjectOptions = [
    { value: "math", label: "Matemática" },
    { value: "literature", label: "Lengua" },
  ];

  const regionOptions = [
    { value: "caba", label: "CABA" },
    { value: "mendoza", label: "Mendoza" },
  ];

  return (
    <FramedContainer>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ width: "100%" }} component="form">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "10px",
            }}
          >
            <Select
              id="region"
              label="Región"
              placeholder="Seleccioná una región"
              value={region}
              onChange={updateField(setRegion, regionOptions)}
              options={regionOptions}
            />
            <Select
              id="grade"
              label="Grado"
              placeholder="Seleccioná un grado"
              value={grade}
              onChange={updateField(setGrade, gradeOptions)}
              options={gradeOptions}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            <Select
              id="subject"
              label="Materia"
              placeholder="Seleccioná una materia"
              value={subject}
              onChange={updateField(setSubject, subjectOptions)}
              options={subjectOptions}
            />
            <Text
              id="topic"
              label="Tema"
              value={topic}
              onChange={updateField(setTopic)}
            />
          </Box>

          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <Text
              id="timeUnit"
              label="Unidad de tiempo"
              value={timeUnit}
              onChange={updateField(setTimeUnit)}
              type="number"
              placeholder="¿En cuantos encuentros (instrucciones) se pretende ejecutar esta planificación?"
              minimumValue={0}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <Text
              id="instruction"
              label="Instrucción"
              value={instruction}
              onChange={updateField(setInstruction)}
              placeholder="Observaciones o pedidos especificos para generar la planifiación."
            />
          </Box>
        </Box>

        <Box sx={{ marginTop: "30px" }}>
          <FilledButton onClick={onClick} disabled={!isSubmitButtonEnabled}>
            {!isGeneratingContentPlanning ? "Generar" : "Generando..."}
          </FilledButton>

          {renderError()}
        </Box>
      </Box>
    </FramedContainer>
  );
}

export default ContentPlanningForm;
