import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FramedContainer from "../framed-container/FramedContainer";
import successToast from "../toasts/SuccessToast";
import errorToast from "../toasts/ErrorToast";
import { useEffect, useState } from "react";
import ApiClient from "../../services/ApiClient";
import Text from "../fields/Text";
import FilledButton from "../buttons/FilledButton";
import { LOGIN_PATH } from "../../routes";
import { useNavigate } from "react-router-dom";
import Feedback from "../../app/Feedback";

function ContentPlanningFeedbackForm({ contentPlanningId }) {
  const apiClient = new ApiClient();
  const navigate = useNavigate();

  const [isFeedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [isSavingFeedback, setSavingFeedback] = useState(false);
  const [feedbackDetail, setFeedbackDetail] = useState();
  const [feedbackRate, setFeedbackRate] = useState();

  const USEFUL_FEEDBACK = "useful";
  const UNUSEFUL_FEEDBACK = "not_useful";

  useEffect(() => {
    setFeedbackSubmitted(false);
    setSavingFeedback(false);
    setFeedbackDetail(undefined);
    setFeedbackRate(undefined);
  }, [contentPlanningId]);

  const saveFeedback = () => {
    setSavingFeedback(true);
    const feedback = new Feedback(feedbackRate, feedbackDetail);
    apiClient
      .saveContentPlanningFeedback(feedback, contentPlanningId)
      .then(() => {
        setSavingFeedback(false);
        setFeedbackSubmitted(true);
        successToast("Feedback enviado.");
      })
      .catch((error) => {
        if (error.status === 403) {
          navigate(LOGIN_PATH);
        } else {
          setSavingFeedback(false);
          setFeedbackSubmitted(false);
          errorToast("Ocurrió un problema al enviar el feedback.");
        }
      });
  };

  const hasContentPlanningId = () => contentPlanningId !== "";

  const renderFeedbackOptions = () => {
    const usefulIconColor = feedbackRate === USEFUL_FEEDBACK ? "green" : "grey";
    const unusefulIconColor =
      feedbackRate === UNUSEFUL_FEEDBACK ? "red" : "grey";
    return (
      <>
        <IconButton
          onClick={() => updateFeedbackRate(USEFUL_FEEDBACK)}
          disabled={isFeedbackSubmitted}
          sx={{ color: usefulIconColor }}
        >
          <Tooltip title="Planificación adecuada">
            <ThumbUpIcon />
          </Tooltip>
        </IconButton>

        <IconButton
          onClick={() => updateFeedbackRate(UNUSEFUL_FEEDBACK)}
          disabled={isFeedbackSubmitted}
          sx={{ color: unusefulIconColor }}
        >
          <Tooltip title="Planificación inadecuada">
            <ThumbDownIcon />
          </Tooltip>
        </IconButton>
      </>
    );
  };

  const updateFeedbackRate = (rate) => {
    setFeedbackRate(rate);
  };

  const updateFeedbackDetail = (event) => {
    setFeedbackDetail(event.target.value);
  };

  const isSubmitButtonEnabled =
    feedbackRate && feedbackDetail && !isFeedbackSubmitted;

  const renderFeedbackForm = () => {
    return (
      <FramedContainer>
        <Box sx={{ display: "flex", flexDirection: "column" }} component="form">
          <Box>
            <Typography
              variant="body1"
              sx={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              ¿Te resultó útil esta planificación?
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              marginTop: "15px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              id="feedback"
              value={feedbackDetail}
              onChange={updateFeedbackDetail}
              placeholder="Observaciones o comentarios."
              disabled={isFeedbackSubmitted}
            />
            {renderFeedbackOptions()}
          </Box>

          <Box sx={{ marginTop: "30px" }}>
            <FilledButton
              onClick={saveFeedback}
              disabled={!isSubmitButtonEnabled}
            >
              {!isSavingFeedback ? "Enviar" : "Enviando..."}
            </FilledButton>
          </Box>
        </Box>
      </FramedContainer>
    );
  };

  return hasContentPlanningId() ? renderFeedbackForm() : undefined;
}

export default ContentPlanningFeedbackForm;
