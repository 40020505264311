import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import {
  Alert,
  CircularProgress,
  Link,
  ListItem,
  ListItemText,
  styled,
} from "@mui/material";
import ApiClient from "../services/ApiClient";
import { LOGIN_PATH } from "../routes";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import successToast from "../components/toasts/SuccessToast";
import errorToast from "../components/toasts/ErrorToast";
import FilledButton from "../components/buttons/FilledButton";

function ContextDetail() {
  const [contextFiles, setContextFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [isFetchingContextFiles, setFetchingContextFiles] = useState(true);

  const apiClient = new ApiClient();

  const navigate = useNavigate();
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  useEffect(() => {
    const getContextFiles = () => {
      setFetchingContextFiles(true);
      apiClient
        .listContextFiles()
        .then(({ content }) => {
          setFetchingContextFiles(false);
          setContextFiles(content);
        })
        .catch((error) => {
          if (error.status === 403) {
            navigate(LOGIN_PATH);
          } else {
            setFetchingContextFiles(false);
            setContextFiles([]);
          }
        });
    };
    getContextFiles();
  }, [navigate]);

  // TODO: Think how can we manage this style out of here and reuse our FilledButton so as to not have repeated code
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const renderFileLinks = () => {
    if (isFetchingContextFiles) {
      return <CircularProgress />;
    } else if (contextFiles.length === 0) {
      return (
        <Alert severity="error" sx={{ marginTop: "15px" }}>
          No pudimos obtener los documentos.
        </Alert>
      );
    }

    return contextFiles.map((file, index) => (
      <ListItem key={index}>
        <ListItemText
          primary={
            <Link href={file.url} color="primary" underline="hover">
              {file.name}
            </Link>
          }
        />
      </ListItem>
    ));
  };

  const handleFileSubmission = async (event) => {
    event.preventDefault();
    if (!file) {
      errorToast("Seleccione un archivo para subir al sistema");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    apiClient
      .addFilesToContext(formData)
      .then(() => successToast("El archivo se cargó exitosamente"))
      .catch(() => errorToast("El archivo no pudo ser cargado"));
  };

  const handleButtonClick = () => {
    document.getElementById("file-input").click();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ display: "inline-block", width: "100%" }}>
        <Typography sx={{ display: "inline-block" }} variant="h4">
          Contexto
        </Typography>
        <Box sx={{ display: "inline-block", float: "right" }}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <Button
              onClick={handleButtonClick}
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Seleccionar archivo
            </Button>

            <VisuallyHiddenInput
              id="file-input"
              type="file"
              onChange={handleFileChange}
              accept=".pdf"
            />

            <FilledButton onClick={handleFileSubmission} disabled={!file}>
              Cargar archivo
            </FilledButton>
          </Box>
        </Box>
      </Box>

      <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
        Documentación utilizada como contexto para generar las respuestas.
      </Typography>

      <List>{renderFileLinks()}</List>
    </Box>
  );
}

export default ContextDetail;
